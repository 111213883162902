<template>
  <b-tabs
    ref="businessSettingTabBar"
    :vertical="verticalTabBar.status"
    :nav-wrapper-class="verticalTabBar.wrapper"
  >
    <b-tab
      title-item-class="d-flex justify-content-start"
    >
      <template #title>
        <feather-icon icon="SmartphoneIcon" />
        <span>Mobile Apps</span>
      </template>
      <b-card>
        <MobileApp :resource-path="mobileAppPath" />
      </b-card>
    </b-tab>
    <b-tab
      title-item-class="d-flex justify-content-start"
    >
      <template #title>
        <feather-icon icon="UserIcon" />
        <span>Merchant Resources</span>
      </template>
      <b-card>
        <MerchantResources :resource-path="merchantResourcesPath" />
      </b-card>
    </b-tab>
    <b-tab
      title-item-class="d-flex justify-content-start"
    >
      <template #title>
        <feather-icon icon="GridIcon" />
        <span>Other Resources</span>
      </template>
      <b-card>
        <OtherResources :resource-path="otherResourcesPath" />
      </b-card>
    </b-tab>
    <b-tab
      title-item-class="d-flex justify-content-start"
    >
      <template #title>
        <feather-icon icon="ShoppingBagIcon" />
        <span>Customer Resources</span>
      </template>
      <b-card>
        <CustomerResources :resource-path="customerResourcesPath" />
      </b-card>
    </b-tab>
  </b-tabs>
</template>

<script>
import {
  BTabs, BTab, BCard,
} from 'bootstrap-vue'

import MobileApp from '@/views/resource/MobileApp.vue'
import MerchantResources from '@/views/resource/MerchantResources.vue'
import OtherResources from '@/views/resource/OtherResources.vue'
import CustomerResources from '@/views/resource/CustomerResources.vue'
import store from '@/store'
import { $themeBreakpoints } from '@themeConfig'

export default {
  components: {
    MobileApp,
    MerchantResources,
    OtherResources,
    CustomerResources,
    BTabs,
    BTab,
    BCard,
  },
  data() {
    return {
      store,
    }
  },
  computed: {
    // Access relavent routes in .env
    mobileAppPath() {
      return process.env.VUE_APP_MOBILE_APP_PATH
    },
    merchantResourcesPath() {
      return process.env.VUE_APP_MERCHANT_RESOURCES_PATH
    },
    otherResourcesPath() {
      return process.env.VUE_APP_OTHER_RESOURCES_PATH
    },
    customerResourcesPath() {
      return process.env.VUE_APP_CUSTOMER_RESOURCES_PATH
    },
    verticalTabBar() {
      if (this.$store.state.app.windowWidth > $themeBreakpoints.lg) {
        return {
          status: true,
          wrapper: 'nav-vertical',
        }
      }
      return {
        status: false,
        wrapper: 'nav-horizontal',
      }
    },
  },
  mounted() {
    // eslint-disable-next-line no-underscore-dangle
    this.__preserveTabOnRefresh('businessSettingTabBar', 'businessSettingTabIndex')
  },
}
</script>
